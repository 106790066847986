
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { UpdatePodDexApiRequest } from "@/data/payload/api/PodDexRequest";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import { Options, prop, Vue } from "vue-class-component";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  ModalMessageEntities,
  UploadMultiple,
} from "@/domain/entities/MainApp";
import { UrgentDeliveryManagementController } from "@/app/ui/controllers/UrgentDeliveryManagementController";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

class Props {
  data = prop<ShipmentBookingDetail>({
    default: new ShipmentBookingDetail({}),
    type: ShipmentBookingDetail,
  });
}
@Options({
  components: {
    UploadFile,
  },
})
export default class SectionUploadPOD extends Vue.with(Props) {
  mounted() {
    this.onResetForm();
  }

  get maxPhoto() {
    return 4;
  }

  proofPhotos: any = [];

  onResetForm() {
    this.isClearFile = true;
    this.form = {
      sentValue: "",
      receiverName: "",
      files: [],
    };
    this.proofPhotos.length = 1;
    this.form.files = new Array(this.maxPhoto).fill("");
  }

  get isDisableConfirm(): boolean {
    return !this.form.receiverName || this.isEmptyPhoto;
  }

  get isEmptyPhoto() {
    return !this.form.files.some((key:any) => key)
  }

  isClearFile = false;
  form = {
    sentValue: "",
    receiverName: "",
    files: [] as any,
  };

  showSuccessPopup() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-success",
        title: "Update POD berhasil!",
        message: "Paket berhasil diperbarui menjadi POD.",
        textSuccess: "OK",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          UrgentDeliveryManagementController.setSttInfo({
            sttId: 0,
            sttNo: "",
          });
          this.$router.push("/urgent-delivery-management/taken-by-recipient/");
        },
      })
    );
  }

  showConfirmation() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: "Update status ke POD?",
        message:
          "Pastikan kembali, paket yang akan di POD ke penerima sudah sesuai.",
        textCancel: "Cek Kembali",
        textSuccess: "Ya, Update",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onUpdate();
        },
      })
    );
  }

  async onUpdate() {
    const isSuccess = await PodDexController.updatePodDex(
      new UpdatePodDexApiRequest({
        attachments: this.form.files.filter((key: any) => key !== ""),
        sttNo: this.data.sttNo,
        isDelivered: true,
        receiverName: this.form.receiverName,
        notDeliveredReason: "",
        remarks: "",
        isCustomTitle: "Update POD Gagal !",
      })
    );
    if (isSuccess) this.showSuccessPopup();
  }

  async onUpload(params: UploadMultiple) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "pod-from-taken-by-recipient/",
          })
        );
        this.form.files[params.index] = fileLink.data;
        if (this.proofPhotos.length < this.maxPhoto)
          this.proofPhotos.push(null);
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  async onRemove(index: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.form.files[index]],
        })
      );
      const statusDel = del.data[this.form.files[index]];
      if (statusDel) {
        this.form.files[index] = "";
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal menghapus Foto ke storage!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
